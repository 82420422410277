import { useEffect } from 'react';
import { useConnectEngine } from './qlik-hooks';
import { useOpenDoc } from './qlik-hooks/Global';

export const DeferConnectEngineAndOpenDoc = ({
  configFromUrl,
  accessToken,
  setQlikSession,
  setQlikApp,
}) => {
  const session = useConnectEngine(configFromUrl, accessToken) || null;
  const app = useOpenDoc(session, { params: [configFromUrl.appname] });

  useEffect(() => {
    if (session && session.handle && app) {
      setQlikSession(session);
      setQlikApp(app);
    }
  }, [app, session]);

  return <></>
};
