import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

const LoginCallback = ({ homePath, loadingElement }) => {
  const { oktaAuth } = useOktaAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const handleLoginRedirect = async () => {
      await oktaAuth.handleLoginRedirect();
    };

    if (oktaAuth.token.isLoginRedirect()) {
      // store redirectUri, it's cleared after `handleLoginRedirect` call
      const redirectUri = oktaAuth.getOriginalUri();
      handleLoginRedirect()
        .then(() => {
          if (!redirectUri) {
            // manual redirect when redirectUri is not set
            // otherwise allow default behavior
            navigate(homePath, { replace: true });
          }
        })
        .catch((e) => {
          // TODO: handle error state(s)
          console.error(e);
        });
    } else {
      // we landed on root path, but do not have login callback params in url query string
      navigate(homePath, { replace: true });
    }
  }, [oktaAuth, navigate, homePath]);

  return loadingElement;
};

export default LoginCallback;
