import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
} from 'react';
import { useConnectEngine } from '../Hooks/qlik-hooks';
import { useOpenDoc } from '../Hooks/qlik-hooks/Global';
import { configs } from '../Config/qlikConfig';
import Loader from '../Components/Loader';
import { useParams, useLocation } from 'react-router-dom';
import { ALL_TRAFFIC, GQ_US, VOGUE_US } from '../Utils/Constants';
import { DeferConnectEngineAndOpenDoc } from '../Hooks/useDeferConnectEngineAndOpenDoc';

const getDefaultBrand = (brandName) => {
  let default_site = ALL_TRAFFIC;
  switch (brandName) {
    case 'vogue':
      default_site = VOGUE_US;
      break;
    case 'gq':
      default_site = GQ_US;
      break;
    default:
      default_site = ALL_TRAFFIC;
  }
  return default_site;
};

const SessionContext = createContext();

export const SessionProvider = ({ props, children }) => {
  const params = useParams();
  const locationSite = useLocation();
  const siteRef = useRef();
  const [session, setQlikSession] = useState({
    call: (config) => {},
    close: null,
    error: null,
    handle: null,
    loading: true,
    suspend: null,
    unsuspend: null,
  });
  const [customFilters, setCustomFilters] = useState(0);
  const searchParams = new URLSearchParams(window.location.search);
  const hasHourName = JSON.parse(searchParams.get('filters'))?.Hour_Name; 
  const [dateTimeFiltersApplied, setDateTimeFiltersApplied] = useState(hasHourName);

  const [app, setQlikApp] = useState({
    call: (...args) => {},
    error: null,
    handle: null,
    loading: false,
  });
  if (locationSite?.state?.site) {
    siteRef.current = locationSite.state.site;
  }
  const paramsPubYearInRange = (item, params) => {
    if (item.pubYear === params.pubYear) {
      return true;
    }
    if (Number(params.pubYear) <= 2021 && item.pubYear === '2021') {
      return true;
    }
    if (Number(params.pubYear) >= 2024 && item.pubYear === '2024') {
      return true;
    }
    return false;
  };

  const configFromUrl =
    params.app === 'app'
      ? configs[0]
      : params.app.toLowerCase() === 'vogue'
      ? configs.find((item) => {
          return params.pubYear
            ? item.brand === params.app && paramsPubYearInRange(item, params)
            : item.brand === params.app;
        })
      : configs.find((item) => item.brand === params.app);

  const brandName = configFromUrl ? configFromUrl.brand : 'global';
  const { accessToken } = props;

  const defaultSite = siteRef.current
    ? siteRef.current
    : getDefaultBrand(configFromUrl.brand);
  let setProps = {
    session,
    app,
    brandName,
    defaultSite,
    setCustomFilters,
    customFilters,
    setDateTimeFiltersApplied,
    dateTimeFiltersApplied,
  };

  return (
    <>
      {accessToken && (
        <DeferConnectEngineAndOpenDoc
          configFromUrl={configFromUrl}
          accessToken={accessToken}
          setQlikSession={setQlikSession}
          setQlikApp={setQlikApp}
        />
      )}

      <SessionContext.Provider value={setProps}>
        {children}
      </SessionContext.Provider>
    </>
  );
};

export const useSession = () => useContext(SessionContext);
