import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCookie } from '../Utils/functions';

const Redirector = () => {
  const navigate = useNavigate();
  const allowedRedirects = ['/vogue','/ad','/vanityfair','/allure','/cnt','/glamour','/gq','/tny','/wired','/voguebusiness','/teenvogue','/ba','/epi','/tatler', '/lci','/woi','/pitchfork','/self','/them','/h&g'];
  useEffect(() => {
    let basePath = getCookie('base');
    if (allowedRedirects.includes(basePath)) navigate(basePath);
    else navigate('/app');
  }, []);
  return <div>Redirector</div>;
};

export default Redirector;
