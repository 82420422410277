import React, { useEffect, useState } from "react";

const Loader = ({showDelayMessage=true, messageDelayTime=10000}) => {
  const [loadingMessage, setLoadingMessage] = useState("");
  useEffect(() => {
    if(showDelayMessage){
      const timer = setTimeout(() => {
        setLoadingMessage(
          "This is taking longer than usual.\nThank you for your patience."
        );
      }, messageDelayTime);
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <div role="status">
      <span className="flex flex-col items-center min-h-screen justify-center">
        <svg
          aria-hidden="true"
          className="inline w-16 h-16 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
          viewBox="0 0 90 90"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M45 0C36.0998 -7.78076e-07 27.3996 2.6392 19.9993 7.58387C12.5991 12.5285 6.83137 19.5566 3.42542 27.7792C0.0194783 36.0019 -0.871673 45.0499 0.864662 53.7791C2.601 62.5082 6.88683 70.5264 13.1802 76.8198C19.4736 83.1132 27.4918 87.399 36.2209 89.1353C44.9501 90.8717 53.9981 89.9805 62.2208 86.5746C70.4434 83.1686 77.4715 77.4009 82.4161 70.0007C87.3608 62.6005 90 53.9002 90 45L85.5 45C85.5 53.0101 83.1247 60.8404 78.6745 67.5006C74.2243 74.1608 67.8991 79.3518 60.4987 82.4171C53.0983 85.4825 44.9551 86.2845 37.0988 84.7218C29.2426 83.1591 22.0262 79.3018 16.3622 73.6378C10.6981 67.9738 6.8409 60.7574 5.2782 52.9012C3.71549 45.0449 4.51753 36.9017 7.58288 29.5013C10.6482 22.1009 15.8392 15.7757 22.4994 11.3255C29.1596 6.87528 36.9899 4.5 45 4.5L45 0Z"
            fill="currentFill"
          />
          <path
            d="M90 45C90 39.0905 88.836 33.2389 86.5746 27.7792C84.3131 22.3196 80.9984 17.3588 76.8198 13.1802C72.6412 9.00156 67.6804 5.68688 62.2208 3.42542C56.7611 1.16396 50.9095 -2.58312e-07 45 0L45 4.5C50.3185 4.5 55.585 5.54756 60.4987 7.58288C65.4124 9.61819 69.8771 12.6014 73.6378 16.3622C77.3986 20.1229 80.3818 24.5876 82.4171 29.5013C84.4524 34.415 85.5 39.6815 85.5 45H90Z"
            fill="currentColor"
          />
        </svg>
        {showDelayMessage &&
          <>
            <span className="text-gray-600 font-ibm-sans text-sm mt-4">
                Loading data...
              </span>
              <span className="text-gray-600 font-ibm-sans text-sm whitespace-pre-line text-center">
                {loadingMessage}
              </span>
          </>
        }
      </span>
    </div>
  );
};

export default Loader;
