/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import { useState, useEffect, useRef, useCallback } from 'react';
import { connectSession } from 'rxq';
import { ReplaySubject, from, combineLatest } from 'rxjs';
import {
  startWith,
  map,
  switchMap,
  tap,
  catchError,
  shareReplay,
  concatMap,
  take,
} from 'rxjs/operators';
import delve from 'dlv';

export const getAccessToken = (accessToken) => {
  return new Promise((resolve, reject) => {
    if (accessToken) {
      resolve(accessToken);
    } else {
      reject('accessToken not available');
    }
  });
};
export const getSocketUrl = (cfg, accessToken) => {
  return new Promise((resolve, reject) => {
    if (accessToken) {
      let socketUrl = (cfg.isSecure ? 'wss://' : 'ws://') +
      cfg.host + '/app/' + cfg.appname +
      `?reloadUri=${(cfg.isSecure ? 'wss://' : 'ws://')}${cfg.host}/sense/app/${cfg.appname}` +
        '&accessToken=' +
        accessToken;
      resolve(socketUrl);
    } else {
      reject('Login has expired, refresh or re-login to continue');
    }
  });
};

export default (config, accessToken) => {
  const call$ = useRef(new ReplaySubject()).current;
  const call = useCallback((config) => {
    call$.next(config);
  }, []);

  const [engine, setEngine] = useState({
    loading: true,
    handle: null,
    error: null,
    close: null,
    suspend: null,
    unsuspend: null,
    call,
  });

  useEffect(() => {
    let sub$, session$;

    if (!config.isCloud) {
      session$ = call$.pipe(
        startWith(config),
        map((config) => {
          return connectSession({ ...config });
        }),
        shareReplay(1)
      );
    } else {
      const accessToken$ = from(getAccessToken(accessToken));
      const socketUrl$ = accessToken$.pipe(
        switchMap((accessToken) => from(getSocketUrl(config, accessToken)))
      );

      session$ = combineLatest([accessToken$, socketUrl$]).pipe(
        switchMap(([accessToken, socketUrl]) =>
          call$.pipe(
            take(1),
            startWith(config),
            // tap(() => console.log('Calling connectSession')),
            map((config) => connectSession({ ...config, url: socketUrl }))
          )
        )
      );
    }

    sub$ = session$
      .pipe(
        switchMap((session) =>
          session.global$.pipe(
            tap((globalHandle) => {
              setEngine((prevState) => ({
                ...prevState,
                loading: false,
                handle: globalHandle,
                error: null,
                close: session.close,
                suspend: session.suspend,
                unsuspend: session.unsuspend,
              }));
            })
          )
        )
      )
      .subscribe();

    session$
      .pipe(switchMap((session) => session.notifications$))
      .subscribe((msg) => {
        const qpsErrorMethod = delve(msg, 'data.method', undefined);

        if (
          [
            'OnLicenseAccessDenied',
            'OnSessionClosed',
            'OnSessionLoggedOut',
            'OnSessionTimedOut',
            'OnEngineWebsocketFailed',
            'OnRepositoryWebsocketFailed',
            'OnDataPrepServiceWebsocketFailed',
            'OnNoEngineAvailable',
            'OnNoRepositoryAvailable',
            'OnNoDataPrepServiceAvailable',
          ].includes(qpsErrorMethod)
        ) {
          setEngine((prevState) => ({
            ...prevState,
            error: msg.data.params,
          }));
        }
      });

    return () => {
      session$.pipe(switchMap((session) => session.close()));
      sub$.unsubscribe();
    };
  }, [config]);

  return engine;
};
