import React, { useEffect, useState, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { ThemeProvider, IntlProvider } from '@condenast/gemini';
import { SessionProvider } from '../Context/SessionContext';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import Loader from '../Components/Loader';
import { AuthContext } from 'react-oauth2-code-pkce';

export const ThemeLayout = () => {
  const [accessToken, setAccessToken] = useState(false);
  const { token, error, loginInProgress } = useContext(AuthContext);

  useEffect(() => {
    async function getAccessToken() {
      try {
        const response = await fetch(
          'https://dash.us.qlikcloud.com/oauth/token',
          {
            headers: {
              'content-type': 'application/json',
            },
            body: JSON.stringify({
              subject_token: token,
              subject_token_type:
                'urn:ietf:params:oauth:token-type:access_token',
              grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
              purpose: 'websocket',
              client_id: '6ccb9cb874cdcddfb200e1888ffe21c9',
            }),
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
          }
        );
        const json = await response.json();
        setAccessToken(json.access_token);
      } catch (e) {
        console.log('error', e);
      }
    }
    if (token) {
      getAccessToken();
    }
  }, [token]);

  useEffect(() => {
    if (error) {
      window.location.reload();
    }
  }, [error]);

  return (
    <>
      {loginInProgress ? (
        <Loader />
      ) : (
        <SessionProvider props={{ accessToken }}>
          <ThemeProvider theme="light">
            <IntlProvider>
              <Outlet />
            </IntlProvider>
          </ThemeProvider>
        </SessionProvider>
      )}
    </>
  );
};

export default ThemeLayout;
